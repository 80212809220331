import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const FAQItem = ({ question, answer, expanded, onClick, delay }) => (
  <Box
    mb={1}
    sx={{
      transition: "background-color 0.3s ease",
      "&:hover": {
        backgroundColor: "#f0f0f0", // Light grey background on hover
      },
    }}
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "8px",
        borderRadius: "8px", // Rounded corners for better visual effect
      }}
    >
      <Typography
        variant="p"
        sx={{
          flexGrow: 1,
          cursor: "pointer",
          color: expanded ? "#fa541c" : "#011b46",
          fontWeight: 500,
        }}
        onClick={onClick}
      >
        {question}
      </Typography>
      <IconButton onClick={onClick}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
    <Collapse in={expanded}>
      <Typography variant="p" sx={{ padding: "10px" }} paragraph>
        {answer}
      </Typography>
    </Collapse>
  </Box>
);

export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What services does Finnai Tech Solutions offer?",
      answer:
        "Finnai Tech Solutions offers a range of services including software development, mobile app development, web design and development, and IT consulting.",
    },
    {
      question: "How can I get a quote for a project?",
      answer:
        "To get a quote, please contact us through our 'Contact Us' page or email us at info@finnai.co.ke with details about your project requirements.",
    },
    {
      question: "What industries do you specialize in?",
      answer:
        "We specialize in a variety of industries including finance, healthcare, education, and e-commerce, among others.",
    },
    {
      question: "Do you offer ongoing support and maintenance?",
      answer:
        "Yes, we offer ongoing support and maintenance for all our projects to ensure they continue to run smoothly and efficiently.",
    },
    {
      question: "Can you help with digital marketing and SEO?",
      answer:
        "Yes, we provide digital marketing and SEO services to help improve your online presence and attract more customers.",
    },
    {
      question: "What is your development process like?",
      answer:
        "Our development process includes initial consultation, requirement gathering, design, development, testing, and deployment, followed by ongoing support and maintenance.",
    },
    {
      question: "Do you work with startups?",
      answer:
        "Absolutely! We love working with startups and helping them bring their innovative ideas to life with our technology solutions.",
    },
    {
      question: "How do you ensure the security of your projects?",
      answer:
        "We follow industry best practices for security, including regular audits, secure coding standards, and data encryption to ensure the security of all our projects.",
    },
    {
      question: "Can you integrate third-party services into my application?",
      answer:
        "Yes, we can integrate various third-party services and APIs to enhance the functionality of your application.",
    },
    {
      question: "How can I track the progress of my project?",
      answer:
        "We provide regular updates and use project management tools that allow you to track the progress of your project in real-time.",
    },
    // Add more FAQs as needed
  ];

  return (
    <Box py={3} sx={{ backgroundColor: "#f5f5f5" }}>
      <Container
        maxWidth="lg"
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
      >
        <Typography
          sx={{ textAlign: "center" }}
          variant="h4"
          style={{ marginBottom: "2rem" }}
          gutterBottom
        >
          Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            expanded={expandedIndex === index}
            onClick={() => handleToggle(index)}
            delay={index * 300}
          />
        ))}
      </Container>
    </Box>
  );
}
