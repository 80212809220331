import React from "react";
import { Container, Typography, Grid2, Box } from "@mui/material";
import image from "../../assets/images/about.png";


export default function AboutHero(props) {
  return (
    <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid2 spacing={4} className="div2equal" alignItems="center">
          <Grid2
            sx={{ order: { xs: 1, md: 0 } }}
            item
            size={{ xs: 12, md: 6 }}
            className="left"
          >
            <Typography
              sx={{ fontWeight: 900, color: "#011b46" }}
              variant="h2"
              component="h1"
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="0"
            >
              About <span style={{ color: "#FA541C" }}>Finnai</span> Tech
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
              variant="body1"
              paragraph
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Finnai Tech Solutions is an IT consulting company that is based in
              Kenya. The company specializes in mobile application development,
              web application development, graphics design, and development of
              RESTful API or Backend development.
            </Typography>
          </Grid2>
          <Grid2
            sx={{ order: { xs: 0, md: 1 }, marginBottom: { xs: 10, md: 0 } }}
            item
            size={{ xs: 12, md: 6 }}
          >
            <img
              src={image}
              alt=""
              style={{
                maxHeight: "300px",
                objectFit: "contain",
                display: "block",
                margin: "auto",
                filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
              }}
              data-aos="slide-right"
              data-aos-delay="0"
            />
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
