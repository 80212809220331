import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  TablePagination,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  Chip,
} from "@mui/material";
import { debounce } from "lodash";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import SortIcon from "@mui/icons-material/Sort";
import { Unpublished } from "@mui/icons-material";

export default function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState(""); // Published/Unpublished filter
  const navigate = useNavigate(); // For navigation to blog view

  useEffect(() => {
    fetchBlogs();
  }, [page, rowsPerPage, searchText, sortBy, sortOrder, statusFilter]);

  const fetchBlogs = () => {
    setLoading(true);
    let url = `/api/blogs?limit=${rowsPerPage}&offset=${
      page * rowsPerPage
    }&sort=${sortBy}&order=${sortOrder}`;
    if (searchText) {
      url = `/api/blogs/wildsearch/${searchText}`;
    }
    if (statusFilter !== "") {
      url += `&published=${statusFilter}`;
    }
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching blogs");
      })
      .then((data) => {
        setLoading(false);
        setBlogs(data?.blogs); // Adjusted for data model
        setCount(data?.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0);
  }, 500);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (blogID) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      fetch(`/api/blogs/${blogID}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) fetchBlogs();
        });
    }
  };

  const handlePublish = (blogID) => {
    fetch(`/api/blogs/${blogID}/publish`, {
      method: "PUT",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) fetchBlogs();
      });
  };

  const handleUnpublish = (blogID) => {
    fetch(`/api/blogs/${blogID}/unpublish`, {
      method: "PUT",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) fetchBlogs();
      });
  };

  const handleRowClick = (blogID) => {
    navigate(`/admin/blogs/view/${blogID}`);
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        {/* Toolbar: Search, Sort, Create */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            Blog Posts
          </Typography>
          <TextField
            label="Search Blogs..."
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            size="small"
            sx={{ marginRight: 2 }}
          >
            <MenuItem value="createdAt">Date</MenuItem>
            <MenuItem value="Views">Views</MenuItem>
            <MenuItem value="Title">Title</MenuItem>
          </Select>
          <IconButton
            onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
          >
            <SortIcon />
          </IconButton>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            size="small"
            sx={{ marginRight: 2 }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="true">Published</MenuItem>
            <MenuItem value="false">Unpublished</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="create"
          >
            Create New Blog
          </Button>
        </Box>

        {/* Blog Table */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Box sx={{ minHeight: "60vh" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Views</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blogs.map((blog) => (
                    <TableRow
                      sx={{
                        height: 60,
                        borderRadius: 2,
                        boxShadow: "4px 4px 8px #60606040",
                        cursor: "pointer", // Make row clickable
                        "& > *": { borderBottom: "unset" }, // Hide row bottom border for spacing
                        mb: 2,
                      }}
                      key={blog.ID}
                      onClick={() => handleRowClick(blog.ID)} // Open view/edit page on click
                    >
                      {/* Feature Image Preview */}
                      <TableCell>
                        <Avatar
                          variant="square"
                          src={blog.FeatureImage}
                          alt="Blog Image"
                          sx={{ width: 60, height: 60 }}
                        />
                      </TableCell>
                      <TableCell>
                        {dayjs(blog.Date).format("MMMM D, YYYY h:mm A")}
                      </TableCell>
                      <TableCell>{blog.Title}</TableCell>
                      <TableCell>{blog.Views}</TableCell>
                      <TableCell>
                        <Chip
                          color={blog.Published ? "success" : "warning"}
                          label={blog.Published ? "Published" : "Unpublished"}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            blog.Published
                              ? handleUnpublish(blog.ID)
                              : handlePublish(blog.ID);
                          }}
                        >
                          {blog.Published ? <Unpublished /> : <PublishIcon />}
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleDelete(blog.ID);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}
