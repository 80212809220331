import img from "../../assets/images//advert.png";
export default function Advert(props) {
  return (
    <div className="advert">
      <div className="container">
        <div className="div2equal">
          <div className="left">
            <h1 data-aos="fade-up" data-aos-delay="0">
              Join our clients who boost their businesses with our solutions
            </h1>
            <button
              onClick={() => {
                window.location.href = "/contact";
              }}
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Try Us
            </button>
          </div>
          <img src={img} alt="" data-aos="slide-right" data-aos-delay="0" />
        </div>
      </div>
    </div>
  );
}
