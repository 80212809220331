import "../Styles/utils.scss";
import "../Styles/contact.scss";
import React, { Component } from "react";
import Footer from "../Components/Utils/Footer";
import Header from "../Components/Utils/Header";
import AboutHero from "../Components/About/ahero";
import HowItWorks from "../Components/About/how_it_works";
import WhyUS from "../Components/About/why_us";
import Map from "../Components/About/map";

export class AboutPage extends Component {
  render() {
    return (
      <div className="contact">
        <Header />
        <AboutHero />
        <HowItWorks />
        <WhyUS />
        <Map />
        <Footer />
      </div>
    );
  }
}

export default AboutPage;

const AHero = () => {
  return (
    <div className="ahero">
      <div className="wrapper">
        <div className="container">
          <h1>About Finnai</h1>
          <p>
            Finnai Tech Solutions is an IT consulting company that is based in
            Kenya. The company specializes in mobile application development,
            web application development, graphics design, and development of
            RESTful API or Backend development.
          </p>
        </div>
      </div>
    </div>
  );
};
