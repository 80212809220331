import React, { useEffect } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import mpic from "../../assets/images/maintanance.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Maintenance() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
    });
  }, []);

  return (
    <Box sx={{ py: 7, backgroundColor: "#f5f5f5" }}>
      <Container maxWidth="md">
        <Typography
          data-aos="fade-up"
          sx={{ fontWeight: 900, textAlign: "center", color: "#011b46" }}
          variant="h2"
          component="h1"
          gutterBottom
        >
          Maintenance &{" "}
          <span style={{ color: "#FA541C" }}>Technical Support</span>
        </Typography>

        <Typography
          sx={{ textAlign: "center", py: 2 }}
          variant="h6"
          color="textSecondary"
          gutterBottom
        >
          We offer 6 months of free technical support for our solutions. After
          the expiry of the warranty period, we offer support as per a Service
          Level Agreement.
        </Typography>
        <img
          src={mpic}
          alt="Maintenance"
          style={{ width: "100%", borderRadius: 8 }}
        />
      </Container>
    </Box>
  );
}
