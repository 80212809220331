import "../Styles/PrivacyPolicy.scss";
import React from "react";
import Header from "../Components/Utils/Header";
import Footer from "../Components/Utils/Footer";

export default function PrivacyPolicy(props) {
  return (
    <div className="AboutPrivacy">
      <div className="headings">
        <Header active="Privacy" />
      </div>

      <div className="vision2030">
        <h1>Privacy Policy</h1>
        <p>
          <b>Finnai Privacy Policy</b>
          <br></br> <br></br>
          <br></br> <br></br>
          <b>1.0 Introduction</b>
          <br></br> <br></br>
          Your privacy is important to us. This privacy statement explains the
          personal data Finnai Tec (“Finnai”) collects, how Finnai processes it,
          and for what purposes.
          <br></br> <br></br>
          This statement should be read together with the Terms and Conditions
          of Use for other Finnai products and services. Where there is a
          conflict, this statement will prevail.
          <br></br> <br></br>
          This statement applies to all customers, suppliers, agents, merchants,
          dealers and all visitors frequenting any of Finnai premises.
          <br></br> <br></br>
          <b>2.0 Definitions</b>
          <br></br> <br></br>
          References to
          <br></br> <br></br>
          2.1 “You” means:
          <br></br> <br></br>
          i)customer- the person who subscribes to, uses or purchases any of our
          products and services or accesses our websites and includes any person
          who accesses any of the products and services you have subscribed to.
          <br></br> <br></br>
          ii)Any agent, dealer and/or merchants who has signed an agreement with
          us and is recognised as a merchant or agent in accordance with any
          applicable laws or Regulations.
          <br></br> <br></br>
          iii) Any visitor that is a person (including
          contractors/subcontractors or any third parties) who gains access to
          any Finnai premises.
          <br></br> <br></br>
          iv)Any supplier who has been contracted by Finnai and executed a
          supplier contract.
          <br></br> <br></br>
          2.2 “Finnai”, “we” or “us”, “our” and “ours” means Finnai Tech
          Solutions The word “includes” means that what follows is not
          necessarily exhaustive and therefore the examples given are not the
          only things/situations included in the meaning or explanation of that
          text.
          <br></br> <br></br>
          <b>3.0 Statement Details</b>
          <br></br> <br></br>
          3.1 Collection of Information
          <br></br> <br></br>
          3.1.1 We collect your personal information with your knowledge and
          consent when you do any of the following (please note that this list
          is not exhaustive):
          <br></br> <br></br>
          a) Register for a specific product or service, including but not
          limited to SIM-card registration, PostPay subscriptions, e-commerce
          platforms, M-PESA and M-PESA-powered services;
          <br></br> <br></br>
          b) Buy, subscribe to or use a Finnai product or service online, on the
          cloud, on a mobile or other device, in a Finnai Tech Solutions Shop or
          other retail outlet;
          <br></br> <br></br>
          c) Subscribe to Finnai or third-party premium rates services, Short
          Message Service (SMS), email or social media platforms;
          <br></br> <br></br>
          d) Ask Finnai for more information about a product or service or
          contact Finnai with a query or complaint;
          <br></br> <br></br>
          e) Respond to or participate in a survey, marketing promotion, prize
          competition or special offer;
          <br></br> <br></br>
          f) Visit, access or use Finnai or third-party websites;
          <br></br> <br></br>
          g) We may also collect your information from other organisations
          including credit-reference bureaus, fraud prevention agencies and
          business directories;
          <br></br> <br></br>
          h) We may collect your information when you interact with us as a
          supplier, agent, merchant or dealer as prescribed in this statement;
          <br></br> <br></br>
          i) We also collect information when you visit any of our premises.
          <br></br> <br></br>
          3.1.2 We do not onboard minors (any person under 18 years of age)
          except where you additionally register on their behalf as their parent
          and/ or legal guardian. If you allow a child to use our services, you
          should be aware that their personal information could be collected as
          described in this statement.
          <br></br> <br></br>
          3.2 What Information is collected?
          <br></br> <br></br>
          The information we collect and store about you includes but is not
          limited to the following:
          <br></br> <br></br>
          3.2.1 Your identity and SIM-card registration information, including
          your name, photograph, address, location, phone number, identity
          document type and number, date of birth, email address, age, gender
          and mobile number portability records.
          <br></br> <br></br>
          3.2.2 Your credit or debit-card information, information about your
          bank account numbers and SWIFT codes or other banking information.
          <br></br> <br></br>
          3.2.3 Your transaction information when you use our MPESA service.
          <br></br> <br></br>
          3.2.4 Your preferences for particular products and services, based on
          information provided by you or from your use of Finnai’s (or third
          party) network, products and services.
          <br></br> <br></br>
          3.2.5 Name, family details, age, profiling information such as level
          of education, bank account status, income brackets, etc. collected as
          part of surveys conducted by Finnai and their agents on behalf of
          Finnai.
          <br></br> <br></br>
          3.2.6 Your contact with us, such as when you: call us or interact with
          us through social media, our chatbot Zuri, ‘snail mail’, email (we may
          record your conversations, social media or other interactions with
          us), register your biometric information such as your voice, finger
          prints etc, visit a Finnai Shop or other retail outlet.
          <br></br> <br></br>
          3.2.7 Your account information, such as your handset type/model,
          tariff, top-ups; subscriptions (including third party subscriptions),
          billing statements, cloud hosting registration details, e-commerce
          registration and usage, M-PESA and mobile money transactions.
          <br></br> <br></br>
          3.2.8 Your call data records: phone numbers that you call or send
          messages to (or receive calls and messages from), log of calls,
          messages or data sessions on the Finnai network and your approximate
          location (save for customer service interactions as noted above we do
          not record or store message or call contents).
          <br></br> <br></br>
          3.2.9 We use Closed Circuit Television (CCTV) surveillance recordings.
          CCTV Devices are installed at strategic locations to provide a safe
          and secure environment in all Finnai premises as a part of our
          commitment to community safety, security and crime prevention.
          <br></br> <br></br>
          3.2.10 When you request us to reserve parking for you, we will collect
          and retain your personal data (name, telephone number, and vehicle
          registration details) when you request Finnai to reserve parking space
          for you and where you use any of our parking facilities as a
          contractor. We use the data you provide to ensure effective visitor,
          contractor and car park management, Health and Safety compliance
          (orderly entry and exiting to and from the car parks and buildings)
          and inventory management.
          <br></br> <br></br>
          3.2.11 We maintain a register of visitors in which we collect and keep
          your personal data such as names, company/institution details,
          telephone number, vehicle registration details and National ID number.
          This information is collected for health, safety and security
          purposes.
          <br></br> <br></br>
          3.2.12 When you use Finnai WIFI for guest and visitors, we collect
          email IDs and will provide user name and password. We record the
          device address and also log traffic information in the form of sites
          visited, duration and date sent/received.
          <br></br> <br></br>
          3.2.13 We may use your medical information to manage our services and
          products to you e.g. when you use our services designed for persons
          with disabilities such as our braille watch for the visually impaired.
          <br></br> <br></br>
          3.2.14 Where you use our voice recognition platform (IVR) we may
          collect and process your voice biometrics.
          <br></br> <br></br>
          3.2.15 We collect your personal information when you visit us for
          purposes of accident and incident reporting. Finnai will collect
          personal data from the injured party or person suffering from ill
          health, such as, Name, Address, Age, next of kin, details of the
          incident to include any relevant medical history. The data is
          collected as Finnai has a legal duty to document workplace
          incidents/accidents and to report certain types of accidents, injuries
          and dangerous occurrences arising out of its work activity to the
          relevant enforcing authority.
          <br></br> <br></br>
          3.2.16 Incidents and accidents will be investigated to establish what
          lessons can be learned to prevent such incidents/accidents reoccurring
          including introduction of additional safeguards, procedures,
          information instruction and training, or any combination of these.
          Monitoring is undertaken but on an anonymised basis. The information
          is also retained in the event of any claims for damages.
          <br></br> <br></br>
          3.3 Use of Information
          <br></br> <br></br>
          We may use and analyse your information for the following purposes:
          <br></br> <br></br>
          3.3.1 Processing products and services that you have bought from
          Finnai Tech Solutions or from third parties on our ecommerce
          platforms;
          <br></br> <br></br>
          3.3.2 Billing you for using our or third-party products or services or
          taking the appropriate amount of credit from you;
          <br></br> <br></br>
          3.3.3 Responding to any of your queries or concerns;
          <br></br> <br></br>
          3.3.4 Verifying your identity information through publicly available
          and/or restricted government databases in order to comply with
          applicable regulatory requirements;
          <br></br> <br></br>
          3.3.5 Carrying out credit checks and credit scoring;
          <br></br> <br></br>
          3.3.6 Keeping you informed generally about new products and services
          and contacting you with offers or promotions based on how you use our
          or third-party products and services unless you opt out of receiving
          such marketing messages (you may contact Finnai at any time to opt out
          of receiving marketing messages);
          <br></br> <br></br>
          3.3.7 to comply with any legal, governmental or regulatory requirement
          or for use by our lawyers in connection with any legal proceedings;
          <br></br> <br></br>
          3.3.8 In business practices including to quality control, training and
          ensuring effective systems operations;
          <br></br> <br></br>
          3.3.9 To protect our network including to manage the volume of calls,
          texts and other use of our network;
          <br></br> <br></br>
          3.3.10 To understand how you use our network, products and services
          for purposes of developing or improving products and services;
          <br></br> <br></br>
          3.3.11 Preventing and detecting fraud or other crimes and for debt
          recovery;
          <br></br> <br></br>
          3.3.12 For research, statistical, survey and other scientific or
          business purposes;
          <br></br> <br></br>
          3.3.13 Provide aggregated data (which do not contain any information
          which may identify you as an individual) to third parties for research
          and scientific purpose;
          <br></br> <br></br>
          3.3.14 Administer any of our online platforms/websites.
          <br></br> <br></br>
          3.4. Categories of Data
          <br></br> <br></br>
          Categories of Personal Data as defined in the Data Protection Act of
          Kenya may be processed depending on the particular types of products
          and services you have subscribed to.
          <br></br> <br></br>
          3.5. Lawful Basis for processing your information
          <br></br> <br></br>
          We will process your personal information based on any of the lawful
          basis provided for under the Data Protection Law:
          <br></br> <br></br>
          3.6. Retention of Information
          <br></br> <br></br>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, the
          need to comply with our internal policy and the applicable legal,
          regulatory, tax, accounting or other requirements. Anonymised
          information that can no longer be associated with you may be held
          indefinitely.
          <br></br> <br></br>
          <b>4.0 Disclosure of Information</b>
          <br></br> <br></br>
          4.1 Any disclosure of your information shall be in accordance with
          applicable law and regulations.
          <br></br>
          Finnai shall assess and review each application for information and
          may decline to grant such information to the requesting party.
          <br></br> <br></br>
          4.2 We may disclose your information to:
          <br></br> <br></br>
          a) Law-enforcement agencies, regulatory authorities, courts or other
          statutory authorities in response to a demand issued with the
          appropriate lawful mandate and where the form and scope of the demand
          is compliant with the law.
          <br></br> <br></br>
          b) Our subsidiaries, associates, partners, software developers or
          agents who are involved in delivering Finnai products and services you
          order or use;
          <br></br> <br></br>
          c) Fraud prevention and Anti money laundering agencies,
          credit-reference agencies;
          <br></br> <br></br>
          d) Publicly available and/or restricted government databases to verify
          your identity information in order to comply with regulatory
          requirements;
          <br></br> <br></br>
          e) Debt-collection agencies or other debt-recovery organisations;
          <br></br> <br></br>
          <br></br> <br></br>
          g) Emergency service providers when you make an emergency call (or
          where such disclosure to emergency service providers is necessary for
          your rescue, health and safety) including your approximate location;
          <br></br> <br></br>
          h) Any other person that we deem legitimately necessary to share the
          data with.
          <br></br> <br></br>
          4.3 Some of your information may be passed on to any person whom you
          receive mobile money from or send or intend to send mobile money to.
          <br /> Your information may be available to any third party involved
          in the operation of the mobile money service including Lipa Na M-PESA
          Merchants, mobile money interoperability partners, ATM Switch
          providers and vendors of the M-PESA money transfer technology
          platform.
          <br></br> <br></br>
          4.4 We shall not release any information to any individual or entity
          that is acting beyond its legal mandate.
          <br></br> <br></br>
          4.5 We will get your express consent before we share your personal
          data with any third party for direct marketing purposes.
          <br></br> <br></br>
          4.6 Direct Marketing
          <br></br> <br></br>
          4.6.1 You may be required to opt in or give any other form of explicit
          consent before receiving marketing messages from us.
          <br></br> <br></br>
          4.6.2 You can ask us to stop sending you marketing messages at any
          time by writing to us or logging into our website, www.finnai.co.ke
          and checking or unchecking relevant boxes to adjust your marketing
          preferences or by following the optout links on any marketing message
          sent to you or by attending to us or contacting us at any time through
          the provided contacts.
          <br></br> <br></br>
          4.6.3 Where you opt out of receiving these marketing messages, this
          will not apply to personal data provided to us as a result of a
          product, service already taken up, warranty registration, product or
          service experience or other transactions].
          <br></br> <br></br>
          <b>5.0 The Use of Cookies</b>
          <br></br> <br></br>
          5.1 We may store some information (using "cookies") on your computer
          when you visit our websites.
          <br /> This enables us to recognise you during subsequent visits. The
          type of information gathered is non-personal (such as: the Internet
          Protocol (IP) address of your computer, the date and time of your
          visit, which pages you browsed and whether the pages have been
          delivered successfully.
          <br></br> <br></br>
          5.2 We may also use this data in aggregate form to develop customised
          services - tailored to your individual interests and needs. Should you
          choose to do so, it is possible (depending on the browser you are
          using), to be prompted before accepting any cookies, or to prevent
          your browser from accepting any cookies at all. This will however
          cause certain features of the web site not to be accessible.
          <br></br> <br></br>
          <b>6.0 The Use of Hyperlinks</b>
          <br></br> <br></br>
          6.1 Our websites may provide hyperlinks to other locations or websites
          on the Internet. These hyperlinks lead to websites published or
          operated by third parties who are not affiliated with or in any way
          related to us and have been included in our website to enhance your
          user experience and are presented for information purposes only.
          <br></br> <br></br>
          6.2 We do not endorse, recommend, approve or guarantee any third-
          party products and services by providing hyperlinks to an external
          website or webpage and do not have any co-operation with such third
          parties unless otherwise disclosed. We are not in any way responsible
          for the content of any externally linked website or webpage.
          <br></br> <br></br>
          6.3 By clicking on a hyperlink, you will leave the Finnai webpage and
          accordingly you shall be subject to the terms of use, privacy and
          cookie policies of the other website that you choose to visit.
          <br></br> <br></br>
          <b>7.0 Access to and Updating your Information</b>
          <br></br> <br></br>
          To update your information, go to any of our applications that collect
          user information and update from there. You can change how we get in
          touch with you and your account details whenever you like.
          <br></br> <br></br>
          <b>8.0 Safeguarding and Protection of Information</b>
          <br></br> <br></br>
          Finnai has put in place technical and operational measures to ensure
          integrity and confidentiality of your data via controls around:
          information classification, access control, cryptography, physical and
          environmental security and monitoring and compliance.
          <br></br> <br></br>
          <b>9.0 International Data Transfers</b>
          <br></br> <br></br>
          From time to time we may need to transfer your personal information
          outside the Republic of Kenya.
          <br></br> <br></br>
          Where we send your information outside Kenya, we will make sure that
          your information is properly protected in accordance with the
          applicable Data Protection Laws.
          <br></br> <br></br>
          <b>10.0 Your Rights</b>
          <br></br> <br></br>
          Subject to legal and contractual exceptions, you have rights under
          data protection laws in relation to your personal data. These are
          listed below: -<br></br> <br></br>
          a) Right to be informed that we are collecting personal data about
          you;
          <br></br> <br></br>
          b) Right to access personal data that we hold about you and request
          for information about how we process it;
          <br></br> <br></br>
          c) Right to request that we correct your personal data where it is
          inaccurate or incomplete;
          <br></br> <br></br>
          d) Right to request that we erase your personal data noting that we
          may continue to retain your information if obligated by the law or
          entitled to do so;
          <br></br> <br></br>
          e) Right to object and withdraw your consent to processing of your
          personal data. We may continue to process if we have a legitimate or
          legal reason to do so;
          <br></br> <br></br>
          f) Right to request restricted processing of your personal data noting
          that we may be entitled or legally obligated to continue processing
          your data and refuse your request;
          <br></br> <br></br>
          g) Right to request transfer of your personal data in [an electronic
          format]. If you wish to exercise any of the rights set out above,
          please contact us on info@finnai.co.ke. We may need to request
          specific information from you to help us confirm your identity and
          ensure your right to access your personal data (or to exercise any of
          your other rights). This is a security measure to ensure that personal
          data is not disclosed to any person who has no right to receive it. We
          may also contact you to ask you for further information in relation to
          your request to speed up our response.
          <br></br> <br></br>
          We try to respond to all legitimate requests within reasonable time.
          Occasionally it could take us longer if your request is particularly
          complex or you have made a number of requests. In this case, we will
          notify you and keep you updated.
          <br></br> <br></br>
        </p>
      </div>
      <Footer />
    </div>
  );
}
