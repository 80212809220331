import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Grid2,
  Card,
  Avatar,
  IconButton,
  Chip,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import dayjs from "dayjs";
import Footer from "../Components/Utils/Footer";
import Header from "../Components/Utils/Header";
import "../Styles/landing.scss";

const BlogPostPage = () => {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [mostViewedBlogs, setMostViewedBlogs] = useState([]);
  const [randomBlogs, setRandomBlogs] = useState([]);
  const [currentCarousel, setCurrentCarousel] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    await fetchBlogs(
      "/api/blogs?limit=5&sort=createdAt&order=DESC",
      setLatestBlogs
    );
    await fetchBlogs(
      "/api/blogs?limit=8&sort=Views&order=DESC",
      setMostViewedBlogs
    );
    await fetchBlogs("/api/blogs?limit=8&sort=Views&order=ASC", setRandomBlogs);
  }
  // Fetch blog data from API
  async function fetchBlogs(url, setter) {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setter(data.blogs || []); // Ensure setter uses an array, even if data is undefined
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setInterval(() => {
      if (latestBlogs.length > 0) {
        handleNextCarousel();
      }
    }, 10000);
  }, [latestBlogs]);

  // Move to the next blog in the carousel
  const handleNextCarousel = () => {
    setCurrentCarousel((prev) => (prev + 1) % latestBlogs.length);
  };

  // Move to the previous blog in the carousel
  const handlePrevCarousel = () => {
    setCurrentCarousel((prev) =>
      prev === 0 ? latestBlogs.length - 1 : prev - 1
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Container maxWidth="lg" sx={{ py: 8, minHeight: "80vh" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box mt={4}>
            {latestBlogs.length > 0 && (
              <Box elevation={3} sx={{ mb: 4 }}>
                <Grid2 container spacing={4}>
                  <Grid2
                    size={{ xs: 12, md: 8 }}
                    sx={{
                      p: 2,
                      boxShadow: "0px 16px 32px #60606040",
                    }}
                    component={Card}
                    onClick={() => {
                      window.location.href =
                        "/blog/" + latestBlogs[currentCarousel]?.Title;
                    }}
                  >
                    <Box position="relative">
                      <Chip
                        label={latestBlogs[currentCarousel]?.Category || "News"}
                        color="primary"
                        sx={{
                          position: "absolute",
                          top: 20,
                          left: 20,
                          zIndex: 2,
                          fontWeight: "bold",
                        }}
                      />
                      <img
                        src={latestBlogs[currentCarousel]?.FeatureImage}
                        alt="Blog Cover"
                        data-aos="slide-left"
                        style={{
                          width: "100%",
                          borderRadius: 8,
                          height: "400px",
                          objectFit: "cover",
                        }}
                      />
                      <IconButton
                        onClick={handlePrevCarousel}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: 10,
                          zIndex: 10,
                          backgroundColor: "rgba(255,255,255,0.5)",
                          borderRadius: "50%",
                        }}
                      >
                        <ArrowBackIos />
                      </IconButton>
                      <IconButton
                        onClick={handleNextCarousel}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: 10,
                          zIndex: 10,
                          backgroundColor: "rgba(255,255,255,0.5)",
                          borderRadius: "50%",
                        }}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                    <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                      {latestBlogs[currentCarousel]?.Title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      gutterBottom
                    >
                      {dayjs(latestBlogs[currentCarousel]?.Date).format(
                        "MMMM D, YYYY"
                      )}
                    </Typography>
                    <Typography variant="body2">
                      {latestBlogs[currentCarousel]?.Excerpt || ""}
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      Latest Blogs
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {latestBlogs.map((blog, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          cursor: "pointer",
                          borderBottom:
                            currentCarousel === index
                              ? "2px solid red"
                              : "none",
                          pb: 2,
                          p: 2,
                          borderRadius: 0,
                          boxShadow: "0px 16px 32px #60606040",
                        }}
                        component={Card}
                        onClick={() => {
                          window.location.href = "/blog/" + blog?.Title;
                        }}
                      >
                        <Avatar
                          variant="rounded"
                          src={blog.FeatureImage}
                          alt={blog.Title}
                          sx={{
                            width: 84,
                            objectFit: "cover",
                            height: 64,
                            mr: 2,
                          }}
                        />
                        <Box>
                          <Typography variant="body2">{blog.Title}</Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            {dayjs(blog.Date).format("MMM D, YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Grid2>
                </Grid2>
              </Box>
            )}

            {mostViewedBlogs.length > 0 && (
              <Box sx={{ my: 10 }}>
                <Typography variant="h5" gutterBottom>
                  Popular
                </Typography>
                <Divider sx={{ mb: 5 }} />
                <Grid2 container spacing={4}>
                  {mostViewedBlogs?.map((blog) => (
                    <Grid2 size={{ xs: 12, md: 3 }} key={blog.ID}>
                      <Card
                        elevation={1}
                        sx={{
                          p: 2,
                          borderRadius: 0,
                          boxShadow: "0px 16px 32px #60606040",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        component={Stack}
                        spacing={1}
                        onClick={() => {
                          window.location.href = "/blog/" + blog?.Title;
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={blog.FeatureImage}
                          alt={blog.Title}
                          sx={{ width: "100%", height: 150, borderRadius: 1 }}
                        />
                        <Typography flexGrow={1} variant="body1" gutterBottom>
                          {blog.Title}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {dayjs(blog.Date).format("MMM D, YYYY")}
                        </Typography>
                      </Card>
                    </Grid2>
                  ))}
                </Grid2>
              </Box>
            )}

            {/* Random Blog Suggestions */}
            {randomBlogs.length > 0 && (
              <Box sx={{ my: 10 }}>
                <Typography variant="h5" gutterBottom>
                  You Missed
                </Typography>
                <Divider sx={{ mb: 5 }} />
                <Grid2 container spacing={4}>
                  {randomBlogs?.map((blog) => (
                    <Grid2 size={{ xs: 12, md: 3 }} key={blog.ID}>
                      <Card
                        elevation={1}
                        sx={{
                          p: 2,
                          borderRadius: 0,
                          boxShadow: "0px 16px 32px #60606040",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          ":hover": "0px 32px 64px #60606050",
                        }}
                        component={Stack}
                        spacing={1}
                        onClick={() => {
                          window.location.href = "/blog/" + blog?.Title;
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={blog.FeatureImage}
                          alt={blog.Title}
                          sx={{ width: "100%", height: 150, borderRadius: 1 }}
                        />
                        <Typography flexGrow={1} variant="body1" gutterBottom>
                          {blog.Title}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {dayjs(blog.Date).format("MMM D, YYYY")}
                        </Typography>
                      </Card>
                    </Grid2>
                  ))}
                </Grid2>
              </Box>
            )}
          </Box>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default BlogPostPage;
