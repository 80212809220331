import React from "react";
import { Container, Typography, Box, Grid2 } from "@mui/material";
import { Api, PlusOne } from "@mui/icons-material";

export default function Portfolio(props) {
  return (
    <Box sx={{ py: 8, backgroundColor: "white" }}>
      <Container>
        <Grid2 container xs={12} alignItems="center">
          <Grid2 size={{ xs: 12, md: 6 }} spacing={2}>
            <Grid2 item xs={12}>
              <Typography
                sx={{ fontWeight: 900, color: "#011b46" }}
                variant="h2"
                component="h1"
                gutterBottom
                data-aos="fade-up"
              >
                Our <span style={{ color: "#fa541c" }}>Portfolio</span>
              </Typography>
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#011b46" }}
                variant="body1"
                paragraph
                gutterBottom
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Software Solutions for your Business
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 5, md: 2 }} my={2} alignItems="center">
            <Grid2 container spacing={5} alignItems="center">
              <Grid2
                size={{ xs: 12, md: 1 }}
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      margin: "auto",
                    }}
                  >
                    20
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Projects</Typography>
              </Grid2>
              <Grid2
                item
                size={{ xs: 12, md: 1 }}
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      marginRight: "auto",
                    }}
                  >
                    10
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Happy Clients</Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 1 }}
                data-aos="fade-up"
                data-aos-delay="900"
              >
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#011b46",
                      fontWeight: 800,
                      margin: "auto",
                    }}
                  >
                    7
                  </Typography>
                  <PlusOne sx={{ color: "#fa541c" }} />
                </Box>
                <Typography variant="body1">Years of Experience</Typography>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 item size={{ xs: 6, md: 4 }} my={2} alignItems="center">
            <Grid2 container spacing={13}>
              <Grid2
                size={{ xs: 12, md: 3 }}
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Typography variant="body1">
                  Delivering innovative solutions across 20+ successful
                  projects."
                </Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 3 }}
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <Typography variant="body1">
                  Trusted by over 10 satisfied clients in Kenya.
                </Typography>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 3 }}
                data-aos="fade-up"
                data-aos-delay="900"
              >
                <Typography variant="body1">
                  Empowering businesses with over 7 years of industry
                  experience.
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
