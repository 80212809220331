import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

export default function Testimonials() {
  const list = [
    {
      desc: "Finnai Tech Solutions delivered a top-notch website for our business. The team's attention to detail and commitment to our vision made the entire process smooth and efficient.",
      name: "John",
    },
    {
      desc: "We needed a mobile app developed within a tight deadline, and Finnai Tech Solutions came through with flying colors. The app works flawlessly and our users love it.",
      name: "Sarah",
    },
    {
      desc: "The branding and graphic design services provided by Finnai Tech Solutions exceeded our expectations. Our new brand identity has received great feedback from our customers.",
      name: "David",
    },
    {
      desc: "Finnai Tech Solutions made our transition to cloud hosting seamless. The team was knowledgeable, responsive, and ensured minimal downtime during the migration.",
      name: "Emily",
    },
    {
      desc: "Setting up professional email hosting was a breeze with Finnai Tech Solutions. Their support team guided us through every step, making it a hassle-free experience.",
      name: "Michael",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === list.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [list.length]);

  return (
    <Box sx={{ py: 8, backgroundColor: "white" }}>
      <Container maxWidth="md">
        <Typography
          data-aos="fade-up"
          sx={{ fontWeight: 900, textAlign: "center", color: "#011b46" }}
          variant="h2"
          component="h1"
          gutterBottom
        >
          Testimonials
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={4}
          sx={{ overflow: "hidden", position: "relative" }}
        >
          <Box
            display="flex"
            sx={{
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`,
              width: `${list.length * 100}%`,
            }}
          >
            {list.map((item, index) => (
              <Card
                key={index}
                sx={{
                  minWidth: "100%",
                  backgroundColor: "white",
                  px: 2,
                  py: 4,
                  boxShadow: 3,
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ lineHeight: 2 }}
                    variant="body1"
                    color="textSecondary"
                  >
                    {item.desc}
                  </Typography>
                  <Typography variant="h6" align="right" color="textSecondary">
                    {item.name}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
          <IconButton
            sx={{ position: "absolute", left: 0, bottom: 0 }}
            onClick={() =>
              setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? list.length - 1 : prevIndex - 1
              )
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </IconButton>
          <IconButton
            sx={{ position: "absolute", right: 0, bottom: 0 }}
            onClick={() =>
              setCurrentIndex((prevIndex) =>
                prevIndex === list.length - 1 ? 0 : prevIndex + 1
              )
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
}
