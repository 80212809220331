import React from "react";
import { Typography, Container, Stack } from "@mui/material";
import MyMap from "../Map/MyMap";

export default function Map(params) {
  return (
    <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
      <Stack
        sx={{
          backgroundColor: "#F1F3F5",
          my: 10,
          borderRadius: 8,
          padding: "1em 0 0 0",
          alignItems: "center", // Center content horizontally
          justifyContent: "center", // Center content vertically
          textAlign: "center", // Center text inside Stack
        }}
        spacing={3}
      >
        <Typography sx={{ fontWeight: 500, color: "#011b46" }} variant="h6">
          Location:{" "}
          <span style={{ color: "#FA541C" }}>Nairobi Garage, Westlands</span>
        </Typography>
        <MyMap />
      </Stack>
    </Container>
  );
}
