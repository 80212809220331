import "../Styles/Terms.scss";
import React from "react";
import Header from "../Components/Utils/Header";
import Footer from "../Components/Utils/Footer";

export default function Terms(props) {
  return (
    <div className="AboutTerms">
      <div className="headings">
        <Header active="Terms" />
      </div>

      <div className="vision2030">
        <h1>Terms and Conditions</h1>
        <p>
          <b>Finnai Terms and Conditions</b>
          <br></br> <br></br>
          <br></br> <br></br>
          The terms and conditions ("Terms and Conditions") in this document
          ("Agreement") describe and stipulate the provisions which govern and
          regulate the legal relationship between Finnai Ltd. (“Finnai”) and/or
          Finnai's Subsidiaries as provider of the Services and you as user of
          the Services ("You" or "User").
          <br></br> <br></br>
          You should read these Terms and Conditions carefully and should not
          accept these Terms and Conditions or register for, access or use the
          Services (collectively as "Use" of the Services) unless You agree to
          the Terms and Conditions. Finnai does not store an individual copy of
          this Agreement entered into with You and we recommend that You save a
          local copy of this Agreement for Your own record.
          <br></br> <br></br>
          You may not Use the Services if You are a (a) person who is not of
          legal age to form a binding contract with Finnai or (b) person who is
          barred from receiving the Services under the laws of any country
          including the country in which You are a resident or from which You
          are using the Services.
          <br></br> <br></br>
          In this Agreement:
          <br></br> <br></br>
          "Service" or “Services” means myFinnai Application and any
          applications, websites, WAP, software, services, Single Sign On
          module, and any marketing programs run under the name of my Finnai
          Tech Solutions, and other related services provided by any third party
          designated by us; and
          <br></br> <br></br>
          "Finnai's Subsidiaries" means all legal entities, companies,
          corporations, firms, partnerships or other entities that are
          controlled by Finnai or are under common control with Finnai. The term
          "controlled" means the ability to direct the management of the
          relevant entity.
          <br></br> <br></br>
          How You may accept the Terms and Conditions,
          <br></br> <br></br>
          How Finnai may change the Terms and Conditions, and
          <br></br> <br></br>
          How You or Finnai may terminate the Terms and Conditions
          <br></br> <br></br>
          <b>1. Acceptance of the Terms and Conditions</b>
          <br></br> <br></br>
          1.1. Before You are permitted to Use the Services You are required to
          accept the Terms and Conditions and any Special Terms (as defined in
          Clause 20 below), as the case may be. Typically, Terms and Conditions
          and any Special Terms are accepted by clicking or tabbing the "accept"
          button if such button or function is available or present via the user
          interface for the respective Service.
          <br></br> <br></br>
          1.2. You also accept the Terms and Conditions or any Special Terms
          when You actually start to Use the Services. In this case You
          understand that You are bound by and Finnai will treat You as bound by
          the Terms and Conditions from the moment You start using the Services.
          <br></br> <br></br>
          <b>2. Changes to the Terms and Conditions</b>
          <br></br> <br></br>
          2.1. Finnai is permitted to amend or alter the Terms and Conditions
          from time to time.
          <br></br> <br></br>
          2.2. Finnai will notify You with at least 15 days prior notice
          ("Notification Period") of any material changes of the Terms and
          Conditions and will provide You with a copy of the revised Terms and
          Conditions either by the use of Your email address provided to Finnai
          as part of the Registration Data or by any other suitable means which
          ensure that You will be able to take notice of the revised Terms and
          Conditions.
          <br></br> <br></br>
          2.3. After the Notification Period has passed You will be prompted to
          accept the revised Terms and Conditions, typically by clicking or
          tabbing the "accept" button if such button or function is available or
          present via the user interface for the respective Service. You may not
          continue to Use the Services unless You accept the revised Terms and
          Conditions.
          <br></br> <br></br>
          2.4. You also accept the revised Terms and Conditions when You
          continue to Use the Services after the Notification Period has passed.
          In this case You are bound by and Finnai will treat You as bound by
          the revised Terms and Conditions from the date on which the revised
          Terms and Conditions entered into effect. You are free to end this
          Agreement at any time should You choose not to accept the revised
          Terms and Conditions.
          <br></br> <br></br>
          <b>
            3. Termination of the Terms and Conditions; Consequences of
            termination
          </b>
          <br></br> <br></br>
          3.1. This Agreement remains in effect unless and until terminated
          either by You or Finnai, as the case may be.
          <br></br> <br></br>
          3.2. You can terminate this Agreement at any time by ceasing use of
          the Services
          <br></br> <br></br>
          3.3. Finnai may terminate this Agreement at any time by providing a 30
          days prior written notice of cancellation to the email address
          provided to Finnai as part of Your Registration Data or by any other
          suitable means which ensure that You will be able to take notice of
          the cancellation.
          <br></br> <br></br>
          3.4. Finnai is entitled to terminate this Agreement at any time and
          without observing any notice period if:
          <br></br> <br></br>
          a. You are in breach of the Terms and Conditions;
          <br></br> <br></br>
          b. You have clearly demonstrated (regardless of whether directly or
          through Your actions or statements or otherwise) that You do not
          intend to comply with the Terms and Conditions;
          <br></br> <br></br>
          c. Finnai, (including Finnai's Subsidiaries) or any supplier or
          partner of Finnai providing the Services to or together with Finnai
          decide to terminate the provision of the Services or any parts thereof
          (either worldwide or in the country where You are a resident or from
          where You are Using the Services) or if any supplier or partner of
          Finnai decides to terminate the entire relationship with Finnai and
          regardless of the reason of such termination, including where Finnai
          or any supplier or partner of Finnai are of the opinion that the
          provision of the Services or parts thereof to You or to Finnai or
          together with Finnai are no longer commercially feasible; and
          <br></br> <br></br>
          d. Finnai (including Finnai's Subsidiaries) or any supplier or partner
          of Finnai providing the Services to or together with Finnai are
          required by applicable law to terminate the provision of the Services
          or parts thereof (for example if due to changes in applicable law or
          due to court rulings or judgments the Services or parts thereof become
          or are considered unlawful).
          <br></br> <br></br>
          3.5. The termination of this Agreement shall have no prejudice to any
          rights, obligations and liabilities that You or Finnai have accrued or
          incurred during the term of this Agreement.
          <br></br> <br></br>
          3.6. You are not permitted to Use the Services if You do not accept
          the revised Terms and Conditions or after this Agreement comes to an
          end, for example, due to termination by either You or Finnai Tech
          Solutions.
          <br></br> <br></br>
          How You may Use the Services and How Finnai may use Your content or
          how You may use Finnai's content
          <br></br> <br></br>
          <b>4. Provision of the Services and limitations of usage</b>
          <br></br> <br></br>
          4.1. The Services are provided to You by Finnai and Finnai Tech
          Solutions Subsidiaries. By using the Service, you can read, access,
          launch, and/or download (where available) the content or other tools
          and applications available in the Application. Content available in
          the Application may be provided by Finnai or third parties. Finnai may
          subcontract all or any part of its activities relating to the
          Services, to any third party designated by Finnai.
          <br></br> <br></br>
          4.2. Unless otherwise specified in this Agreement You are permitted to
          Use the Services for personal and non-commercial use and purposes only
          and You will not reproduce, duplicate, copy, sell, trade or resell any
          of the Services or parts thereof.
          <br></br> <br></br>
          4.3. When You Use the Services You will at all times observe these
          Terms and Conditions and any applicable law or regulation in the
          relevant jurisdictions including the jurisdiction where You are a
          resident or from where You are using the Services.
          <br></br> <br></br>
          4.4. Finnai may at any time and in its sole discretion without prior
          warning or notice:
          <br></br> <br></br>
          a. change the Services or suspend and/or cease providing the Services
          or any part of the Services;
          <br></br> <br></br>
          b. disable or suspend Your Use of the Services including access to
          Your User account(s) and any files or other content contained in Your
          account(s) either temporarily or permanently;
          <br></br> <br></br>
          c. set a limit on the number of transmissions You may send or receive
          through the Services or on the amount of storage space used for the
          provision of the Services or any part of the Services to You; and
          <br></br> <br></br>
          d. pre-screen, review, flag, filter, modify, refuse, reject, block
          access to or remove any or all Content from the Services.
          <br></br> <br></br>
          4.5. Finnai will use commercially reasonable efforts to (a) ensure
          that the Services are available to You without undue disruption,
          interruption or delay and (b) keep any disruption, interruption or
          delay of the Services to a minimum.
          <br></br> <br></br>
          4.6. You are not permitted to Use any of the Services (including any
          attempts to do so) other than through the interface made available to
          You by Finnai and You will not Use the Services (including any
          attempts to do so) through any automated tools (including software
          and/or hardware), functions, services or otherwise (including scripts
          or web crawlers).
          <br></br> <br></br>
          4.7. You will comply with and follow all instructions made available
          to You by Finnai in connection with the Services and You will not
          engage in any activity that may cause interference with or disruption
          to the Services or any servers, networks or other equipment connected
          to the Services.
          <br></br> <br></br>
          4.8. Some of the Services can be used or are particularly useful when
          used over mobile networks. You should be aware that Your network
          provider may charge You for access to its network, the duration of
          Your mobile phone's/ mobile device's connection to the network and the
          data volume used to Use the Services. You are entirely responsible to
          check with Your network provider whether any such costs may apply
          before Using the Services in this respect.
          <br></br> <br></br>
          4.9. Use of the Services requires a device which is compatible with
          the Services, and may require a device such as a computer, Internet
          access (fees may apply), and certain software (fees may apply),
          including obtaining updates or upgrades from time to time. Because use
          of the Services involves hardware, software and Internet access, your
          ability to use the Services may be affected by the performance of
          these factors. High speed Internet access is strongly recommended. You
          acknowledge and agree that such system requirements, which may change
          from time to time, are solely your responsibility.
          <br></br> <br></br>
          4.10. You consent to receiving electronically all agreements, notices,
          disclosures and other communications that Finnai may provide to You as
          part of the Services, including via e-mails and push notifications, at
          the details provided by You or as otherwise as deemed appropriate by
          Finnai.
          <br></br> <br></br>
          <b>5. Advertisements</b>
          <br></br> <br></br>
          5.1. You agree that Finnai, as part of the Services, places or
          displays to You advertisements, promotion materials or other content
          and materials or products for promotional purposes.
          <br></br> <br></br>
          5.2. In any event, Finnai will only send You push notifications,
          marketing emails or newsletters for marketing purposes where you have
          explicitly opted to receive such information and communication, for
          example, when You registered for the Services.
          <br></br> <br></br>
          <b>6. Software and software updates</b>
          <br />
          Software and software updates to the Services Finnai may automatically
          download and install updates from time to time (including firmware
          updates for the devices you registered with the Services) ("Software
          Updates"). Such Software Updates may be in various forms and are
          generally provided for the purposes of improving the performance,
          security and reliability of the Services or any product or device used
          to access the Services. Such updates may include bug fixes,
          enhancements to the Services or parts thereof, products or devices and
          updates and enhancements to any software previously installed
          (including entirely new versions). Such Software Updates may take up
          additional user-accessible internal storage of Your device.
          <br></br> <br></br>
          <b>7. Content provided in the Services and content rights</b>
          <br></br> <br></br>
          7.1. All content that is made available in the Services or accessible
          as part of or by the Use of the Services (including audio and sound
          files, data files, images, music, photographs, software, videos and
          written text) ("Content") is entirely the responsibility of the
          originator of such Content. The Content may include advertisements,
          promotional material and documents or other sponsored Content.
          <br></br> <br></br>
          7.2. The Content may be protected by proprietary or intellectual
          property rights of third parties (such as partners, advertisers and
          sponsors or their agents who provide such Content to Finnai Tech
          Solutions ). You are not permitted to modify, rent, lease, loan, sell,
          distribute or create derivative works based on any Content (either in
          whole or in part) or to grant licenses in the Content.
          <br></br> <br></br>
          7.3. You understand that by using the Services You are exposed to the
          risk that You may find some Content offensive, indecent or
          objectionable and that any Use of the Services as regards such
          exposure is entirely at Your own risk.
          <br></br> <br></br>
          <b>8. User Content and User Content License</b>
          <br></br> <br></br>
          8.1. By uploading, transmitting, creating, posting, displaying or
          otherwise providing any information, materials, documents, media files
          or other content on or through the Services ("User Content") You
          permit Finnai, Finnai's Subsidiaries and other Users of the Services
          to use the User Content to the extent that this is required for the
          provision of the Services, and in this respect grant Finnai, Finnai's
          Subsidiaries and other Users of the Services an irrevocable,
          unlimited, worldwide, royalty-free, and non-exclusive license to copy,
          reproduce, adapt, modify, edit, distribute, translate, digitize,
          publish, publicly perform and publicly display the User Content ("User
          Content License"), such User Content License in any event to be
          limited, however, to the purposes of the Services.
          <br></br> <br></br>
          8.2. The User Content License shall include a right for Finnai Tech
          Solutions and Finnai's Subsidiaries to (a) perform all technical steps
          necessary to process and prepare the User Content for use in the
          Services, including any modification and/or adaption required to
          provide the Services to Users and/or to transmit or distribute the
          User Content over public networks and in media and (b) make available
          and sublicense the User Content to third parties for the use of the
          User Content in connection with the provision of the respective
          services by these third parties.
          <br></br> <br></br>
          8.3. Finnai does not claim ownership of the User Content and You will
          retain any copyright and any other rights to any User Content provided
          by You on or through the Services.
          <br></br> <br></br>
          8.4. Any protection and enforcement of any intellectual property
          rights which exist or pertain to the User Content are entirely Your
          responsibility and Finnai is not obliged to protect and enforce the
          User Content on Your behalf.
          <br></br> <br></br>
          8.5. You are entirely responsible to backup Your User Content stored
          with Finnai or through the Services to another location outside the
          Services (e.g. by means of creating local copies or backups with
          specialized online backup services) to avoid loss of Your User Content
          and other data.
          <br></br> <br></br>
          <b>9. Finnai Services License</b>
          <br></br> <br></br>
          9.1. Finnai, Finnai's Subsidiaries and/or any supplier or licensor of
          Finnai are the owners and shall be entitled to all proprietary rights
          which may exist in the Services, including all legal right, title and
          interest in and to the Services, and all intellectual property rights
          worldwide, regardless of whether registered/legally secured or not.
          <br></br> <br></br>
          9.2. Finnai grants You a worldwide, non-transferrable, non-assignable,
          non-exclusive, personal and royalty free license to Use the Services
          (including any software, products or materials provided to You as part
          of the Services) and in the form provided to You by Finnai ("Finnai
          License"). The Finnai Tech Solutions License entitles You to enjoy the
          benefits of the Services in accordance with these Terms and
          Conditions.
          <br></br> <br></br>
          9.3. Except for the rights granted to You in this Agreement, Finnai
          Tech Solutions retains all rights in or pertaining to the Services.
          <br></br> <br></br>
          9.4. You are not permitted to (a) grant any other User or third party
          a license to Use the Services or otherwise to access Your account or
          the Services, (b) Use the Services to provide services to other Users
          or any other third parties or (c) otherwise assign, grant a sublicense
          in, or grant a security interest in or over the Finnai License or any
          rights under it, loan or lease the Services and/or the Finnai Finnai
          License, or otherwise transfer the Finnai License or any rights under
          it to any third party.
          <br></br> <br></br>
          9.5. You are not permitted to copy, edit, modify, alter or create a
          derivative work of, reverse engineer, decompile or otherwise attempt
          to extract the source code of Finnai Services (or any part of it),
          unless expressly permitted by Finnai in writing, or to the extent
          permitted under the laws applicable to You, and You will not permit or
          grant a license to any third party to do so.
          <br></br> <br></br>
          <b>10. Trademarks</b>
          <br></br> <br></br>
          10.1. You are not entitled to use any of Finnai's (including Finnai's
          Subsidiaries) trade names, trademarks, service marks, logos, domain
          names, or other distinctive brand features ("Finnai's Brands") without
          Finnai's prior written consent. To the extent that You are entitled to
          use Finnai's Brands under a separate written agreement with Finnai,
          such use is only permitted in accordance with such separate agreement.
          <br></br> <br></br>
          10.2. You are not permitted to remove, obscure, conceal, modify or
          otherwise alter any proprietary rights notices, signs, trademarks,
          service marks, trade names, logos or other marks of Finnai Tech
          Solutions , Finnai's Subsidiaries or any third party (including
          copyright and trade mark notices) which pertain to, are affixed to or
          which are contained within the Services and You agree not to use any
          such signs, trademarks, service marks, trade names, logos or other
          marks of Finnai, Finnai's Subsidiaries or any third party in a way
          that is intended to, likely to or foreseeable to mislead others or
          cause confusion about the owner, license holder or authorized User, as
          the case may be, of such marks, names or logos.
          <br></br> <br></br>
          What You should know about your Registration Data, Account Data and
          privacy
          <br></br> <br></br>
          <b>11. Registration data and Users account</b>
          <br></br> <br></br>
          11.1. When You Use the Services You may be required to provide
          information about Yourself before You can continue to Use the Services
          ("Registration Data").
          <br></br> <br></br>
          11.2. You agree to provide accurate, current and complete Registration
          Data and to update Your Registration Data as required in order to keep
          it accurate, current and complete.
          <br></br> <br></br>
          <b>12. Passwords and account security</b>
          <br></br> <br></br>
          12.1. In order to Use the Services You are required to open a User
          account and to provide a User ID and password ("Account Data"), and
          register using either an e-mail address of your choice or Your
          existing Facebook, Google Plus, or Finnai Account.
          <br></br> <br></br>
          12.2. The Services offer You an integrated registration solution
          through a single sign on process ("SingleSignOn"). SingleSignOn means
          that once You open the User account with Finnai, You would be able to
          automatically access all Services without the need to register and
          sign-on separately for each individual Service. SingleSignOn also
          allows You to sign in and log out from all Services and through all of
          Your devices at the same time.
          <br></br> <br></br>
          12.3. You will keep your Account Data safe and secure at all times and
          prevent unauthorised access to Your Account Data and Your account by
          third parties, in particular by avoiding obvious User IDs or
          passwords, by changing Your password regularly and by ensuring that
          You do not disclose your password(s) or grant any other User or third
          party access to Your Account Data or Your account.
          <br></br> <br></br>
          12.4. Finnai may follow any instructions given by You and may regard
          any instructions (a) received from or issued by a User or third party
          using or providing your Account Data or (b) which are attributable to
          You or Your Account Data as Your instructions.
          <br></br> <br></br>
          12.5. You agree not to use any Account Data or account of any other
          User or person than Yourself without permission of the User or person
          holding the respective account.
          <br></br> <br></br>
          12.6. You will notify Finnai immediately by email to
          support@finnai.co.ke upon becoming aware of any unauthorized use of
          any of Your Registration Data or Account Data or any other breach of
          security.
          <br></br> <br></br>
          12.7. PLEASE NOTE THAT You will not be able to change Your
          Registration Data or Account Data after registration. If you have any
          queries, please contact Finnai at support@finnai.co.ke
          <br></br> <br></br>
          <b>13. Privacy and protection of personal data</b>
          <br></br> <br></br>
          13.1. Finnai is committed to the protection of Your personal data. See
          our Privacy Policy (http://finnai.co.ke/privacy) to learn more about
          how Finnai protects and handles Your personal data and information
          when You Use the Services. You agree to the Use of Your Registration
          Data, and all other personal data and information in accordance with
          Finnai Tech Solutions privacy policy. Please note in particular that
          Finnai Tech Solutions and Finnai’s partners use various technologies
          to collect and store information when you access any particular
          service or Service or when you interact with any service or services
          offered to or by Finnai’s partners. You agree that Finnai Tech
          Solutions and Finnai’s partners may collect, store and use this data
          and information to provide recommendations and personalization of the
          Services offered to You, which may include sending of electronic and
          other communications in accordance with Clause 4.10 above.
          <br></br> <br></br>
          How You and Finnai are liable under these Terms and Conditions
          <br></br> <br></br>
          <b>14. YOUR WARRANTIES AND REPRESENTATIONS</b>
          <br></br> <br></br>
          14.1. YOU ARE ENTIRELY RESPONSIBLE FOR THE USER CONTENT PROVIDED BY
          YOU AND FOR ANY CONSEQUENCES ARISING IN CONNECTION WITH THAT USER
          CONTENT (INCLUDING ANY LOSS OR DAMAGE SUFFERED OR INCURRED BY Finnai
          Tech Solutions AND Finnai'S SUBSIDIARIES). IN PARTICULAR, YOU WARRANT
          AND REPRESENT TO Finnai THAT:
          <br></br> <br></br>
          A. YOU ARE THE OWNER OF ALL RIGHTS PERTAINING TO THE USER CONTENT OR
          OTHERWISE AUTHORIZED TO GRANT Finnai THE USER CONTENT LICENSE;
          <br></br> <br></br>
          B. THE USER CONTENT WILL NOT INFRINGE ANY INTELLECTUAL PROPERTY OR
          OTHER THIRD PARTY RIGHTS;
          <br></br> <br></br>
          C. THE USER CONTENT WILL NOT CONTAIN ANY MATERIAL WHICH IS HARMFUL,
          INACCURATE, PORNOGRAPHIC, ABUSIVE, OBSCENE, THREATENING, DEFAMATORY,
          OR WHICH IS OTHERWISE ILLEGAL OR WHICH DOES NOT COMPLY WITH APPLICABLE
          LAW OR Finnai'S CONTENT GUIDELINES;
          <br></br> <br></br>
          D. THE USER CONTENT WILL NOT CONTAIN ANY VIRUSES OR OTHER HARMFUL
          SOFTWARE, CODE OR SIMILAR MEANS AND DEVICES WHICH COULD DAMAGE, HARM,
          DISABLE OR OTHERWISE IMPACT OR LIMIT THE FUNCTION AND PERFORMANCE OF
          THE SERVICES AND/OR ANY DEVICE ACCESSING SUCH USER CONTENT. REGARDLESS
          OF WHETHER THIS DEVICE BELONGS TO Finnai OR ANY OTHER USER OR THIRD
          PARTY INCLUDING SERVER, NETWORKS NODES OR ANY SIMILAR EQUIPMENT;
          <br></br> <br></br>
          E. THE USER CONTENT WILL COMPLY AND CONFORM TO ANY AGE CLASSIFICATION
          RULES AND REQUIREMENTS (INCLUDING ACCURATE AND ADEQUATE CLASSIFICATION
          AND RATING OF ANY USER CONTENT, AS THE CASE MAY BE) UNDER THE LAWS OF
          ANY COUNTRY, INCLUDING THE COUNTRY IN WHICH YOU ARE A RESIDENT OR FROM
          WHICH YOU ARE USING THE SERVICES; AND
          <br></br> <br></br>
          F. THE USE OF THE USER CONTENT BY Finnai OR Finnai Tech Solutions
          SUBSIDIARIES WILL NOT IMPOSE ANY OBLIGATION UPON Finnai Tech Solutions
          OR Finnai'S SUBSIDIARIES TO PAY ANY KIND OF MONETARY CONTRIBUTION
          (INCLUDING LICENSE FEES, DUES OR OTHERWISE) TO ANY THIRD PARTY (IN
          PARTICULAR COLLECTING SOCIETIES).
          <br></br> <br></br>
          14.2. YOU AGREE TO INDEMNIFY AND HOLD HARMLESS Finnai AND THE Finnai
          SUBSIDIARIES FROM ANY LOSS, DAMAGE, LIABILITY OR EXPENSE INCURRED BY
          Finnai AND THE Finnai SUBSIDIARIES AS A RESULT OF ANY BREACH OF THESE
          WARRANTIES.
          <br></br> <br></br>
          <b>15. Finnai'S WARRANTIES AND REPRESENTATIONS</b>
          <br></br> <br></br>
          15.1. UNLESS OTHERWISE EXPRESSLY STATED IN THIS AGREEMENT, Finnai Tech
          Solutions , THE Finnai SUBSIDIARIES, AND ITS SUPPLIERS, PARTNERS
          AND/OR LICENSORS PROVIDE THE SERVICES ON BEST EFFORT BASIS ONLY AND
          EXCLUDE ALL WARRANTIES AND CONDITIONS OF ANY KIND WHETHER EXPRESSLY OR
          IMPLIED. IN PARTICULAR BUT WITHOUT LIMITATION, Finnai Tech Solutions ,
          THE Finnai SUBSIDIARIES, AND ITS SUPPLIERS, PARTNERS AND/OR LICENSORS
          DO NOT WARRANT OR REPRESENT THAT:
          <br></br> <br></br>
          A. THE SERVICES ARE FIT FOR ANY PURPOSE OR MEET YOUR REQUIREMENTS OR
          ARE PROVIDED TO YOU WITHOUT ANY ERRORS OR DEFICIENCIES OR THAT THEY
          ARE IN COMPLIANCE WITH ANY QUALITY LEVELS, AS THE CASE MAY BE;
          <br></br> <br></br>
          B. THE SERVICES ARE AVAILABLE TO YOU AT ANY TIME AND PROVIDED TO YOU
          WITHOUT DISRUPTION, INTERRUPTION OR DELAY;
          <br></br> <br></br>
          C. THE SERVICES DO NOT INFRINGE ANY COPYRIGHT OR VIOLATE ANY LAWS
          WHETHER IN THE COUNTRY WHERE YOU ARE A RESIDENT OR ELSEWHERE IN THE
          WORLD;
          <br></br> <br></br>
          D. ANY INFORMATION (WHETHER ORAL OR WRITTEN) OBTAINED BY YOU FROM
          Finnai OR AS A RESULT OF YOUR USE OF THE SERVICES (INCLUDING ANY
          MATERIALS OR PRODUCTS) WILL BE SUITABLE, ACCURATE, COMPLETE, OR
          RELIABLE; AND
          <br></br> <br></br>
          E. THAT ANY DEFICIENCIES IN THE SERVICES, INCLUDING ANY DEFECTS IN
          PERFORMANCE, OPERATION OR FUNCTIONALITY OF THE SERVICES OR ANY
          SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE FIXED,
          CORRECTED OR OTHERWISE REMEDIED.
          <br></br> <br></br>
          15.2. ANY DOCUMENTS OR MATERIAL (INCLUDING ANY SOFTWARE AND FIRMWARE
          UPDATES) DOWNLOADED, INSTALLED OR OTHERWISE OBTAINED THROUGH THE USE
          OF THE SERVICES ARE PROVIDED BY Finnai "AS IS" AND AT YOUR OWN RISK.
          Finnai IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR MOBILE PHONE OR
          MOBILE PHONE'S, SOFTWARE, COMPUTER SYSTEM OR OTHER DEVICE OR DEVICE'S
          SOFTWARE OR ANY LOSS OF DATA THAT IS CAUSED BY OR RESULTS FROM THE
          DOWNLOAD AND/OR USE OF ANY SUCH DOCUMENTS AND/OR MATERIAL.
          <br></br> <br></br>
          <b>16. YOUR LIABILITY</b>
          <br></br> <br></br>
          16.1. YOU ARE ENTIRELY RESPONSIBLE FOR ANY BREACH OF YOUR OBLIGATIONS
          UNDER
          <br></br> <br></br>
          A. THESE TERMS AND CONDITIONS; AND
          <br></br> <br></br>
          B. ANY APPLICABLE LAW OR REGULATION IN THE RELEVANT JURISDICTIONS,
          INCLUDING THE JURISDICTION WHERE YOU ARE A RESIDENT OR FROM WHERE YOU
          ARE USING THE SERVICES AND FOR THE CONSEQUENCES OF ANY SUCH BREACH,
          INCLUDING ANY LOSS OR DAMAGE WHICH Finnai OR ANY THIRD PARTY MAY INCUR
          OR SUFFER. Finnai WILL HAVE NO RESPONSIBILITY TO YOU OR TO ANY THIRD
          PARTY IN RESPECT OF SUCH BREACH.
          <br></br> <br></br>
          17. Finnai'S LIABILITY
          <br></br> <br></br>
          17.1. SUBJECT TO CLAUSE 17.3, Finnai AND THE Finnai Tech Solutions
          SUBSIDIARIES ARE NOT LIABLE TO YOU, REGARDLESS OF THE LEGAL GROUNDS,
          IN PARTICULAR WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY
          THEORY OF LIABILITY, AND WHETHER OR NOT THE POSSIBILITY OF SUCH DAMAGE
          OR LOSSES HAS BEEN NOTIFIED TO Finnai Tech Solutions , FOR:
          <br></br> <br></br>
          A. ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES;
          <br></br> <br></br>
          B. ANY LOSS OF INCOME, BUSINESS, ACTUAL OR ANTICIPATED PROFITS,
          OPPORTUNITY, GOODWILL OR REPUTATION (WHETHER DIRECT OR INDIRECT);
          <br></br> <br></br>
          C. ANY DAMAGE TO AND/OR CORRUPTION OR LOSS OF DATA (WHETHER DIRECT OR
          INDIRECT);
          <br></br> <br></br>
          D. ANY LOSS OR DAMAGE AS A RESULT OF:
          <br></br> <br></br>
          I. ANY BREACH OF THE TERMS AND CONDITIONS OF THIS AGREEMENT OR ANY
          OTHER AGREEMENT OR CONTRACTUAL RELATIONSHIP BETWEEN Finnai AND YOU
          WHICH IS ATTRIBUTABLE TO NEGLIGENCE ON THE PART OF Finnai Tech
          Solutions , THE Finnai SUBSIDIARIES AND ITS SUPPLIERS, PARTNERS AND/OR
          LICENSORS;
          <br></br> <br></br>
          II. ANY RELIANCE PLACED BY YOU ON THE SUITABILITY, ACCURACY,
          COMPLETENESS, RELIABILITY OR EXISTENCE OF ANY (A) SERVICES (INCLUDING
          ANY SOFTWARE, INFORMATION, DOCUMENTS, MATERIALS MADE AVAILABLE TO YOU
          AS PART OR IN COURSE OF THE USAGE OF THE SERVICES) OR (B) ADVERTISING
          OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY
          ADVERTISER OR SPONSOR WHOSE ADVERTISING (INCLUDING ANY PROMOTIONAL
          MATERIAL) IS MADE AVAILABLE ON OR BY YOUR USE OF THE SERVICES;
          <br></br> <br></br>
          III. ANY CHANGES, MODIFICATIONS, EXTENSIONS OR LIMITATIONS (INCLUDING
          ANY SUSPENSION OF YOUR USE OF THE SERVICES, ACCESS TO YOUR ACCOUNT AND
          ACCOUNT DATA OR YOUR REGISTRATION DATA) TO THE SERVICES, OR ANY
          PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR
          ANY PART OF THEM); OR
          <br></br> <br></br>
          IV. THE USE OF YOUR ACCOUNT DATA BY ANY OTHER PERSON THAN YOURSELF
          (REGARDLESS OF WHETHER WITH OR WITHOUT YOUR KNOWLEDGE). YOU ARE FULLY
          RESPONSIBLE AND LIABLE TO COMPENSATE Finnai FOR ANY DAMAGE AND/OR LOSS
          INCURRED DUE TO THE USE OF YOUR ACCOUNT DATA BY SOMEONE ELSE.
          <br></br> <br></br>
          17.2. IN THE EVENT THAT Finnai OR THE Finnai SUBSIDIARIES ARE LIABLE
          FOR A BREACH OF THESE TERMS AND CONDITIONS, Finnai'S LIABILITY SHALL
          BE LIMITED TO DAMAGES WHICH Finnai Tech Solutions MUST TYPICALLY
          EXPECT AT THE TIME OF THE CONCLUSION OF THIS AGREEMENT DUE TO THE
          CIRCUMSTANCES KNOWN AT THE TIME.
          <br></br> <br></br>
          17.3. NOTHING IN THESE TERMS AND CONDITIONS SHALL EXCLUDE OR LIMIT
          Finnai'S
          <br></br> <br></br>
          A. LIABILITY FOR DEATH, PERSONAL INJURY OR FRAUD OR IN ACCORDANCE WITH
          ANY PRODUCT LIABILITY ACT; AND
          <br></br> <br></br>
          B. WARRANTY OR LIABILITY FOR DAMAGES OR LOSSES WHICH MAY NOT BE
          LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. IF THE LAWS IN THE
          USER'S JURISDICTION DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES,
          TERMS OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
          LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF
          IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, ONLY THE
          LIMITATIONS WHICH ARE LAWFUL IN THAT JURISDICTION WILL APPLY TO THE
          USER AND Finnai'S LIABILITY AND WARRANTY WILL BE LIMITED TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          <br></br> <br></br>
          18. Force Majeure
          <br></br> <br></br>
          18.1. Finnai is not liable or responsible for any failure to perform,
          or delay in performance of any of Finnai's obligations under this
          Agreement that is caused by events outside Finnai's reasonable control
          ("Force Majeure Event"), in particular (without limitation) (a)
          unavailability of public or private telecommunication networks (b)
          acts, decrees, legislation, regulations or restrictions of any
          government or (c) strikes, lock-outs or other industrial action, civil
          commotion, riot, invasion, terrorist attacks or threats of terrorist
          attacks, war (whether declared or not) or any natural disaster.
          <br></br> <br></br>
          18.2. Finnai's performance under this Agreement is deemed to be
          suspended for the period that Force Majeure Event continues, and
          Finnai will have an extension of time for performance for the duration
          of that period.
          <br></br> <br></br>
          18.3. Finnai will use its reasonable efforts to bring the Force
          Majeure Event to a close or to find a solution by which Finnai Tech
          Solutions obligations under this Agreement may be performed despite
          the Force Majeure Event.
          <br></br> <br></br>
          19. Links to third party sites and other content
          <br></br> <br></br>
          19.1. The Services may contain hyper links to content resources and
          services of both Finnai and other third parties, including other
          websites or other applications ("External Resources"). Finnai Tech
          Solutions is not responsible for and has no control over the content
          (including advertisements, products and other materials) provided,
          made available and/or displayed to You under these External Resources
          and is not liable for any damage or loss incurred by You due to or as
          a result of the content (including any reliance by You on the
          availability, accuracy, applicability or completeness of the content)
          which is made available to You, provided to You or displayed to You
          under these External Resources.
          <br></br> <br></br>
          19.2. You may from time to time and as a result of or through the Use
          of the Services purchase or avail Yourself of services, goods or
          software which is provided to You by a third party. Your use of such
          other services, goods or software may be subject to separate terms and
          conditions between You and the respective third party. This Agreement
          does not affect Your legal relationship with that third party and
          Finnai is not liable or responsible for any services, goods or
          software provided to You by any third party.
          <br></br> <br></br>
          What else You should know and understand
          <br></br> <br></br>
          20. Special terms and conditions
          <br></br> <br></br>
          20.1. The Services offer You access to a suite of Finnai and other
          third party services. Special terms and conditions which, among
          others, describe specific features and characteristics of each
          individual particular service may apply and stipulate additional legal
          provisions (collectively referred to as "Special Terms").
          <br></br> <br></br>
          20.2. Any Special Terms apply in addition and in conjunction with
          these Terms and Conditions. In the event of a conflict between these
          Terms and Conditions and any of the Special Terms, the Special Terms
          shall take precedence over these Terms and Conditions.
          <br></br> <br></br>
          21. Language of this Agreement Finnai may provide You with a
          translation of the English version of this Agreement. This translation
          is provided for Your convenience only. In the event of any conflict
          between the English language version and the translation version of
          these Terms and Conditions and to the extent permissible by applicable
          law, the English language version takes precedence over any
          translation version.
          <br></br> <br></br>
          22. Procedure for making claims and notices regarding infringement of
          intellectual property rights and other rights
          <br></br> <br></br>
          22.1. Finnai may respond to notices of alleged copyright infringement
          or violation of other laws in accordance with applicable law and may
          terminate, suspend or block access (either temporary or permanently)
          to accounts of Users who repeatedly infringe copyright laws or violate
          other applicable law.
          <br></br> <br></br>
          22.2. Any notices regarding any copyright infringement or violation of
          other laws by the Services, any content provided with or displayed in
          the Services or any User Content should be sent to
          support@finnai.co.ke
          <br></br> <br></br>
          23. Confidentiality
          <br></br> <br></br>
          23.1. The Services may contain information which is confidential to
          Finnai including, without limitation, all information designated as
          confidential or recognizable as trade or business secrets or for other
          reasons recognizable or made available to You as confidential.
          <br></br> <br></br>
          23.2. Unless stated otherwise in these Terms and Conditions You will
          (a) keep such information confidential for an unlimited period of time
          and not disclose such information without Finnai's prior written
          consent and (b) neither record or otherwise utilize such information
          unless required for the purposes of this Agreement.
          <br></br> <br></br>
          23.3. Clause 23.1 shall not apply in the event that You are required
          to disclose information which is confidential to Finnai according to
          applicable law. In this case and to the extent permissible by
          applicable law, You will notify Finnai immediately of any disclosure
          of any information which is confidential to Finnai.
          <br></br> <br></br>
          24. General provisions
          <br></br> <br></br>
          24.1. Finnai will rely only on the written Terms and Conditions. Any
          representations, statements or agreements made or entered into
          elsewhere, whether directly or indirectly, written or oral or in
          advertising are not binding towards Finnai unless expressly confirmed
          in writing by Finnai to You.
          <br></br> <br></br>
          24.2. Finnai may provide You with notices (including notices relating
          to changes to the Agreement or termination of the Services or parts
          thereof) by email, ordinary mail, or postings on or via the Services.
          <br></br> <br></br>
          24.3. If Finnai does not exercise or enforce any legal right or remedy
          which is set out in this Agreement or which Finnai Tech Solutions has
          the benefit of under any applicable law, this will not be construed as
          a formal waiver of Finnai's rights or remedies and such rights or
          remedies will remain available to Finnai Tech Solutions.
          <br></br> <br></br>
          24.4. If any provision of this Agreement is held to be invalid,
          illegal or unenforceable (in whole or in part) as determined by a
          court of law having jurisdiction to decide on this matter, the
          validity, legality and enforceability of the remaining provisions
          shall not in any way be affected or impaired.
          <br></br> <br></br>
          24.5. Each of the Finnai's Subsidiaries shall be a third party
          beneficiary to the Agreement and shall be entitled to directly enforce
          and rely upon any provision of this Agreement which confers a benefit
          on (or rights in favor of) them. Subject to the foregoing, no other
          person shall be a third party beneficiary to this Agreement.
          <br></br> <br></br>
          24.6. You are not permitted to transfer, assign or otherwise dispose
          of this Agreement which is personal to You, or any of Your rights or
          obligations arising under this Agreement without the prior written
          consent of Finnai.
          <br></br> <br></br>
          24.7. This Agreement and Your relationship with Finnai under this
          Agreement shall be governed and construed in accordance with the laws
          of the jurisdiction where You are resident, without regard to its
          conflict of law provisions and subject to the non-exclusive
          jurisdiction of the courts of such jurisdiction, to resolve any legal
          matter arising in relation to the Agreement. Notwithstanding the
          foregoing, Finnai may apply for injunctive remedies (or an equivalent
          type of urgent legal relief) in any jurisdiction.
        </p>
      </div>
      <Footer />
    </div>
  );
}
