import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { css, keyframes } from "@emotion/react";

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUpStyles = (delay) => css`
  &.enter {
    opacity: 0;
    transform: translateY(20px);
  }
  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    animation: ${slideUp} 500ms forwards;
    animation-delay: ${delay}ms;
  }
`;

const SlideUpList = ({ items }) => {
  return (
    <TransitionGroup>
      {items.map((item, index) => (
        <CSSTransition
          key={index}
          timeout={500 + index * 200}
          classNames="slideUp"
        >
          <div css={slideUpStyles(500 + index * 200)}>{item}</div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default SlideUpList;
