// Home.js
import React, { useEffect, useState } from "react";
import { Box, Card, Grid2, Typography } from "@mui/material";
import { TopItem } from "../home/TopItem";
import Pie from "../home/Pie";
import Bar from "../home/Bar";
import { BarChart } from "@mui/x-charts";

function Home() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetch(`/api/visits/stats`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching stats");
      })
      .then((data) => setStats(data))
      .catch((e) => console.error("Failed to load visit stats:", e));
  }, []);

  return (
    <Box marginTop={8} padding={1}>
      <Grid2 container spacing={3}>
        {/* Display Total Visits */}
        <Grid2 size={{ xs: 6, sm: 3, md: 2.4 }}>
          <TopItem
            title="Total Visits"
            stats={stats?.totalVisits || 0}
            trend="up"
          />
        </Grid2>

        {/* Display Unique Pages */}
        <Grid2 size={{ xs: 6, sm: 3, md: 2.4 }}>
          <TopItem
            title="Unique Visits"
            stats={stats?.uniquePages || 0}
            trend="up"
          />
        </Grid2>

        {/* Display Most Visited Page */}
        <Grid2 size={{ xs: 6, sm: 3, md: 2.4 }}>
          <TopItem title="Blogs" stats={stats?.blogs || 0} trend="up" />
        </Grid2>

        {/* Display Visits by Country */}
        <Grid2 size={{ xs: 6, sm: 3, md: 2.4 }}>
          <TopItem title="Enquiries" stats={stats?.enquiries || 0} trend="up" />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3, md: 2.4 }}>
          <TopItem title="Messages" stats={stats?.messages || 0} trend="up" />
        </Grid2>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography pt={2} pl={2} variant="body1">
              Monthly Visits
            </Typography>
            <BarChart
              title="Monthly Visits"
              xAxis={[
                {
                  scaleType: "band",
                  data: stats
                    ? stats.visitsOverTime.map((item) => item.label)
                    : [],
                },
              ]}
              series={[
                {
                  data: stats
                    ? stats.visitsOverTime.map((item) => item.value)
                    : [],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid2>
        <Grid2 size={{ md: 6, xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography pt={2} pl={2} variant="body1">
              Visits by Region
            </Typography>
            <BarChart
              title="Visits by Region"
              xAxis={[
                {
                  scaleType: "band",
                  data: stats
                    ? stats.visitsByCity.map((item) => item.label)
                    : [],
                },
              ]}
              series={[
                {
                  data: stats
                    ? stats.visitsByCity.map((item) => item.value)
                    : [],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography pt={2} pl={2} variant="body1">
              Visits by Page
            </Typography>
            <BarChart
              title="Visits by Page"
              xAxis={[
                {
                  scaleType: "band",
                  data: stats
                    ? stats.visitsByPage.map((item) => item.label)
                    : [],
                },
              ]}
              series={[
                {
                  data: stats
                    ? stats.visitsByPage.map((item) => item.value)
                    : [],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Home;
