import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Divider,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { debounce } from "lodash";

export default function NewsLetters() {
  const [newsletters, setNewsletters] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchNewsletters();
  }, [page, rowsPerPage, searchText]);

  const fetchNewsletters = () => {
    setLoading(true);
    const url = searchText
      ? `/api/newsletters/wildsearch/${searchText}`
      : `/api/newsletters/mynewsletters/${rowsPerPage}/${page * rowsPerPage}`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching newsletters");
      })
      .then((data) => {
        setLoading(false);
        setNewsletters(data?.data);
        setCount(data?.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0);
  }, 500);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadCSV = () => {
    const csvRows = [
      ["Email", "Date Subscribed"], // Header row
      ...newsletters.map((newsletter) => [
        newsletter.Email,
        dayjs(newsletter.createdAt).format("MMMM D, YYYY h:mm A"),
      ]),
    ];

    // Convert to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvRows.map((row) => row.join(",")).join("\n");

    // Create a download link and trigger it
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "newsletters.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            Newsletters
          </Typography>
          <TextField
            label="Search Email..."
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={downloadCSV}
            disabled={loading || newsletters.length === 0}
          >
            Download CSV
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Date Subscribed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newsletters.map((newsletter) => (
                  <TableRow key={newsletter.ID}>
                    <TableCell>{newsletter.Email}</TableCell>
                    <TableCell align="right">
                      {dayjs(newsletter.createdAt).format(
                        "MMMM D, YYYY h:mm A"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}
