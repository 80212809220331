import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  Alert,
  CircularProgress,Grid2
} from "@mui/material";
import { styled } from "@mui/system";

export default function Pitch(props) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await fetch("/api/newsletters/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: email }),
      });

      if (response.ok) {
        setSuccess("Thank you for subscribing!");
        setEmail("");
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (err) {
      setError("Failed to subscribe, please try again later.");
      setTimeout(() => {
        setError("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PitchContainer>
      <Container>
        <Grid2 container spacing={3} sx={{ color: "white" }}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography
              variant="h3"
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="0"
            >
              Subscribe
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              paragraph
              data-aos="fade-up"
            >
              Stay ahead of the curve! Sign up for our newsletter and get
              exclusive insights into the latest software development trends,
              delivered straight to your inbox.
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                xs={12}
                sx={{
                  width: "100%",
                  marginBottom: "1rem",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.MuiFormLabel-filled": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                }}
                gutterBottom
                data-aos="fade-up"
              >
                <InputLabel>Email address</InputLabel>
                <OutlinedInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ color: "white" }}
                  xs={12}
                  label="Email address"
                  type="email"
                  required
                />
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  color: "white",
                  border: "2px solid white",
                  "&:hover": { backgroundColor: "white", color: "#000" },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress /> : "Join Now"}
              </Button>
              {error && (
                <Alert color="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert color="success" sx={{ mt: 2 }}>
                  {success}
                </Alert>
              )}
            </form>
          </Grid2>
        </Grid2>
      </Container>
    </PitchContainer>
  );
}

const PitchContainer = styled(Box)({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: "1em 0",
  display: "flex",
  alignItems: "center",
  minHeight: "80vh",
});
