import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Card,
  Link,
} from "@mui/material";
import branding from "../../assets/images/branding.png";
import web from "../../assets/images/web.png";
import mobile from "../../assets/images/mobile.png";
import hosting from "../../assets/images/hosting.png";
import ui from "../../assets/images/ux.png";
import email from "../../assets/images/emails.png";
import marketing from "../../assets/images/marketing.png";
import EnquiryDialog from "./EnquiryDialog";

export default function OtherServices() {
  return (
    <Box sx={{ py: 5 }}>
      <Container maxWidth="lg">
        <Typography
          data-aos="fade-up"
          sx={{ fontWeight: 900, color: "white" }}
          variant="h2"
          component="h1"
          gutterBottom
        >
          Our <span style={{ color: "#FA541C" }}>Services</span>
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Item
            image={web}
            title="Web application development"
            desc="Build interactive websites and web applications that run on a web browser."
          />
          <Item
            image={mobile}
            title="Mobile application development"
            desc="Create feature-rich mobile apps for iOS and Android devices."
          />
          <Item
            image={ui}
            title="Graphics Design"
            desc="Develop complex software solutions to meet the specific needs of large organizations."
          />
          <Item
            image={marketing}
            title="Marketing"
            desc="Attract potential customers through targeted campaigns and build brand awareness."
          />
          <Item
            image={hosting}
            title="Cloud hosting"
            desc="We provide cloud infrastructure to keep your software and website online and accessible."
          />
          <Item
            image={email}
            title="Email hosting"
            desc="Offer a reliable platform to send and receive business emails."
          />
        </Grid>
      </Container>
    </Box>
  );
}

const Item = ({ image, title, desc }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid data-aos="fade-up" item xs={12} md={4}>
        <Card elevation={3} sx={{ p: 2, textAlign: "center", borderRadius: 4 }}>
          <Box
            data-aos="fade-right"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={image}
              alt={title}
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              data-aos="fade-right"
              sx={{ color: "#011b46" }}
              variant="h6"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography
              data-aos="fade-right"
              variant="body1"
              color="textSecondary"
            >
              {desc}
            </Typography>
            <Link
              data-aos="fade-right"
              sx={{
                mt: 2,
                marginLeft: "auto",
                display: "block",
                cursor: "pointer",
              }}
              onClick={handleOpenDialog}
            >
              Learn More
            </Link>
          </Box>
        </Card>
      </Grid>

      <EnquiryDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={title}
      />
    </>
  );
};
