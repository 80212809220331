import Advert from "../Components/Landing/Advert";
import FAQ from "../Components/Landing/faq";
import Hero from "../Components/Landing/Hero";
import Pitch from "../Components/Landing/Pitch";
import Portfolio from "../Components/Landing/portfolio";
import Services from "../Components/Landing/Services";
import Pricing from "../Components/Landing/Pricing";
import Footer from "../Components/Utils/Footer";
import Header from "../Components/Utils/Header";
import "../Styles/landing.scss";
import Maintenance from "../Components/Landing/Maintenance";
import Testimonials from "../Components/Landing/Testimonials";

export default function Landing(props) {
  return (
    <div className="landing">
      <Header active="Home" />
      <Hero />
      <Services />
      <Portfolio />
      <Maintenance />
      <Pitch />
      <Pricing />
      <Advert />
      <FAQ />
      <Testimonials />
      <Footer />
    </div>
  );
}
