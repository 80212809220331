import {
  MapContainer,
  Popup,
  Marker,
  ZoomControl,
  LayersControl
} from "react-leaflet";
import L from "leaflet";
import BaseMaps from "./BaseMaps";
import "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function MyMap(props) {
  return (
    <MapContainer
      style={{ width: "100%", height: "500px" }}
      center={[-1.2654753, 36.7990896]}
      zoom={18}
      maxZoom={18}
      zoomControl={true}
    >
      <LayersControl position="bottomright">
        <BaseMaps />
      </LayersControl>
      <Marker position={[-1.2654753, 36.7990896]}>
        <Popup>Nairobi Garage, Westlands, Nairobi</Popup>
      </Marker>
      <ZoomControl position="bottomright" />
    </MapContainer>
  );
}
